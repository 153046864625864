@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  flex-direction: column-reverse;

  @include p-size-tablet {
    margin-top: 96px;
    flex-direction: row;

    @include p-size-desktop {
      gap: 64px;
    }
  }
}

.separator {
  margin-top: 4px;
  height: 4px;
  width: 0;
  background-color: $ds-purple-500;
}

.separatorGrey {
  margin-top: 4px;
  height: 4px;
  width: 0;
  background-color: $ds-grey-400;
}

.imageContainer {
  @include p-size-desktop {
    order: 1;
  }
}

.textContainer {
  @include p-size-desktop {
    order: 2;
  }
}

.imageContainer {
  display: flex;

  img {
    width: 100%;
    height: auto;
  }
}

.badge {
  height: auto;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.ctaLink {
  @include p-size-mobile {
    width: 100%;
  }
}

.bigImage {
  @include p-size-tablet {
    .imageContainer,
    .textContainer {
      width: 50%;
    }
  }
}

.smallImage {
  @include p-size-tablet {
    .imageContainer {
      width: 25%;
    }

    .textContainer {
      width: 70%;
    }
  }

  @include p-size-mobile {
    align-items: flex-start;

    .imageContainer {
      max-width: 80%;
    }
  }
}

.left {
  @include p-size-desktop {
    order: 1;
  }
}

.right {
  @include p-size-desktop {
    order: 2;
  }
}
